textarea:focus, input:focus{
    outline: none;
}

.TimeInput{
    border:none;
    color: #222;

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding: 0;
    margin: 0;
}

.TimeContainer{
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
    padding: 0;
}