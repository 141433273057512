.MenuItem{
    position: relative;
    width: fit-content;
    height: 25px;
    overflow: hidden;
}

.MenuItem:hover::before{

    content:'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    font-size: 1em;
    position: absolute;
    color:transparent;
    text-decoration-style: wavy;
    text-decoration-color: #6212FF;
    text-decoration-line: underline;
    text-decoration-thickness: 0.1rem;
    animation: animate 1s ease-out;
}

@keyframes animate
{
    0%
    {
        transform: translateX(-20px);
    }
    100%
    {
        transform: translateX(-1px);
    }
}