/** {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}*/

.htmlRoot {
    min-width: 1440px;
    height: 100%;
}

@media (max-width: 1440px) {
    .htmlRoot {
        min-width: 1440px;
    }
}

@media (max-width: 600px) {
    .htmlRoot {
        min-width: 360px;
    }
}

body {
    height: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

#root {
    height: 100%;
}

input:-webkit-autofill {
    transition:
        background-color 5000s ease-in-out 0s,
        color 5000s ease-in-out 0s;
    transition-delay: 5000s;
}

* {
    font-family: "Inter", sans-serif !important;
}

@font-face {
    font-family: "Inter";
    font-weight: normal;
    src: url(./fonts/Inter/Inter-Regular.ttf) format("truetype");
}

.main-scroll {
    overflow: inherit !important;
    border: none;
    box-shadow: none;
    padding-right: 0 !important;
}

.main-scroll::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.main-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #ddd5ed;
    height: 10px;
}

::-webkit-scrollbar {
    width: 0.4em;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(218, 218, 223, 1);
    border-radius: 10px;
}

button {
    background: none;
    border: none;
    cursor: pointer;
}