.TeacherWelcomeBanner {
  border-radius: 20px;
  background: #6212ff;
  width: 100%;
  padding: 24px 0 24px 30px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 60px;
}

.TeacherWelcomeBannerHeader {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
  width: 459px;
}

.TeacherPersonalDataStep {
  display: flex;
  flex-direction: column;
  width: 599px;
}

.TeacherPersonalDataStepWider {
  display: flex;
  flex-direction: column;
}

.TeacherWelcomeBannerText {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.TeacherQuestionnaireTitle {
  color: #222;
  font-size: 24px;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
}

.TeacherQuestionnaireSubTitle {
  color: #67677a;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.TeacherQuestionnaireTopBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.TeacherQuestionnaireDropDownEdit {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.TeacherQuestionnaireDropDownTitle {
  color: #222;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 21px */
  margin-top: 2px;
}

.TeacherQuestionnaireBirthdayEdit {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.TeacherPersonalDataForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
}

.TeacherPersonalData {
  margin-bottom: 60px;
}

.TeacherQuestionnaireClassesAimsTitle {
  color: #222;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%; /* 24px */
  border-radius: 10px;
  background: rgba(187, 187, 200, 0.17);
  padding: 12px 0 12px 20px;
}

.TeacherQuestionnaireClassesAimsOuterContainer {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.TeacherQuestionnaireSwitcherTitle {
  color: #222;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%; /* 24px */
}

.TeacherQuestionnaireSwitcherSubTitle {
  color: #67677a;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 21px */
}
.TeacherQuestionnaireSwitcherText {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.TeacherQuestionnaireSwitcherBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.TeacherSwitcherContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.TeacherQuestionnaireSwitcherExclamationText {
  width: 568px;
  color: #222;
  font-size: 13px;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
}

.TeacherQuestionnaireExclamationBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;
}

.TeacherQuestionnaireAutocompleteContainer {
  max-width: 424px;
  width: 100%;
}

.TeacherQuestionnaireEducationOuterContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 40px;
}

.TeacherQuestionnaireStillStudying {
  margin-top: 16px;
  padding-left: 172px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.TeacherQuestionnaireShowToStudents {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.TeacherQuestionnaireAddFieldsButton,
.TeacherQuestionnaireAddFieldsButton:hover {
  color: #222;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%; /* 24px */
  border-bottom: 1px solid #9e9eb0;
  width: fit-content;
  padding: 0 0 4px 0;
}

.TeacherQuestionnaireAddFieldSeparator {
  border-bottom: 1px dashed #bbbbc8;
  border-top: none;
  margin-top: 40px;
}

.TeacherQuestionnaireEducationOuterContainerWithDelete {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.TeacherQuestionnaireDeleteStatementButton {
  margin-top: 40px;
  cursor: pointer;
  height: fit-content;
}

.TeacherQuestionnaireEducationOuterContainerWithoutDelete {
  width: 598px;
}

.TeacherQuestionnaireExclamationBox {
  border-radius: 10px;
  background: #f7f7f7;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  margin-top: 30px;
}

.TeacherQuestionnaireExclamationText {
  color: #222;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.TeacherQuestionnaireExclamationInnerBox {
  display: flex;
  flex-direction: row;
  gap: 14px;
}

.TeacherQuestionnaireWeekDay {
  font-size: 16px;
  font-weight: 500;
  line-height: 150%; /* 24px */
}

.TeacherQuestionnaireChooseTimeBoxHided {
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.TeacherQuestionnaireTimingContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 40px;
}

.TeacherQuestionnaireTimingInnerContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.TeacherQuestionnaireTimeOfDayText {
  color: #67677a;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.TeacherQuestionnaireTimeOfDayRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.TeacherQuestionnaireTimeButton {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%; /* 24px */
  width: 64px;
  height: 32px;
  border-radius: 4px;
}

.TeacherQuestionnaireTimingsRow {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.TeacherQuestionnaireChooseButtons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 5px;
  width: fit-content;
}

.TeacherQuestionnaireChooseButtonsBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.TeacherQuestionnaireWeekDayTitleRow {
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin: 0;
}

.TeacherQuestionnaireProgramExamplesContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  margin-top: 30px;
}

.TeacherQuestionnaireClassesNumberExampleBox {
  height: 94px;
  border-radius: 20px;
  border: 1px solid #f0efff;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(230, 229, 240, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0 20px 20px;
  width: 100%;
}

.TeacherQuestionnaireLevelExampleBox {
  height: 94px;
  border-radius: 20px;
  border: 1px solid #f0efff;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(230, 229, 240, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 15px 20px 20px;
  width: 100%;
}

.TeacherQuestionnaireProgramExampleTitle {
  color: #67677a;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.TeacherQuestionnaireProgramExampleSubTitle {
  color: #222;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%; /* 24px */
  margin: 0;
}

.TeacherQuestionnaireProgramExampleGreyText {
  color: #67677a;
  font-size: 13px;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
}

.TeacherQuestionnaireClassesNumberExampleInnerBox {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.TeacherQuestionnaireProgramExampleBlackText {
  color: #222;
  font-size: 13px;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
}

.TeacherQuestionnaireLevelDescrBox {
  display: flex;
  flex-direction: row;
  gap: 5px;
  height: fit-content;
  align-items: flex-end;
}

.TeacherQuestionnaireLevelPointsBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.TeacherQuestionnaireLevelPointsLine {
  border-radius: 9px;
  background: #6212ff;
  height: 6px;
}

.TeacherQuestionnaireLevelPointsContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.TeacherQuestionnaireProfileLinksTitle {
  color: #222;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.TeacherQuestionnaireProfileLinksInnerContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.TeacherQuestionnaireProfileLinksOuterContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  margin-top: 30px;
}

.TeacherQuestionnaireProfileLinksSiteName {
  color: #222;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.TeacherQuestionnaireProfileLinksOuterBox {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.TeacherQuestionnaireProfileLinksInnerBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 17px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.TeacherQuestionnaireProfileLinksSiteNameBox {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.TeacherQuestionnairePriceSubjects {
  color: #222;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%; /* 24px */
  display: flex;
  padding: 12px 442px 12px 20px;
  align-items: center;
  border-radius: 10px;
  background: #f0efff;
  margin-top: 40px;
  margin-bottom: 20px;
  white-space: nowrap;
}

.TeacherQuestionnairePriceAimsOuterContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.TeacherQuestionnairePriceAimsInnerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.TeacherQuestionnairePriceAimsNameBox {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.TeacherQuestionnairePriceAimsPriceBox {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.TeacherQuestionnairePriceAimsMinutes {
  color: #9e9eb0;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.TeacherQuestionnaireModalBigPhoto {
  object-fit: contain;
  background-color: white;
}

.TeacherQuestionnaireMiniatures {
  object-fit: cover;
  background-color: white;
}

.TeacherQuestionnaireInputPhoto {
  display: none;
}
