
.CancelModalTitle{
    color: var(--text-black, #222);
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 140%; /* 33.6px */
    margin-bottom: 20px;
}

.CancelReasonsModalTitle{
    color: #222;
    font-size: 24px;
    font-weight: 600;
    line-height: 140%; /* 33.6px */
    margin-bottom: 30px;
}

.CancelModalMainText{
    color: var(--text-black, #222);
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%; /* 21px */
    margin-bottom: 40px;
}

.CancelModalButtonGroup{
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: row;
    gap:12px;
}

.CancelModalVericalButtonGroup{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}