.main {
    border-radius: 20px;
    height: auto;
    background-color: rgba(255, 255, 255, 0);
}

.height {
    height: auto;
}

.progress-bar {
    margin-top: 80px;
    height: 4px;
}

@media (max-width: 599px) {
    .progress-bar {
        margin-top: 40px;
    }
}
